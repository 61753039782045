import axios from "axios";

const getUrlApi = () => {
  switch (process.env.DEPLOYMENT_ENV.trim()) {
    case "local":
      return "https://localhost:5001";
    case "development":
      return "https://api.dev-iars-ticketingtool.chili.ch";
    case "production":
      return "https://api.tickets.i-ars.net";
    default:
      return "https://api.tickets.i-ars.net";
  }
};

export const configAxios = () => {
  axios.defaults.baseURL = getUrlApi();
  axios.interceptors.request.use(function (config) {
    const token = `bearer ${localStorage.getItem("jwtToken")}`;
    config.headers.Authorization = token;

    return config;
  });
};
