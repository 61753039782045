
export interface User {
    [key: string]: any
    id: string,
    name: string,
    lastname: string,
    abbreviation: string,
    email: string,
    password: string,
    confirmPassword: string,
}

export const defaultUser: User = {
    id: "",
    name: "",
    lastname: "",
    abbreviation: "",
    email: "",
    password: "",
    confirmPassword: "",
}