import axios from "axios";
import { User } from "../interfaces/user";

export const getCurrent = () => {
    return axios.get("/users/getCurrent");
}

export const getAll = () => {
    return axios.get("/users/getAll");
}

export const getById = (id: string) => {
    return axios.get("/users/getById", { params: { id } })
}

export const add = (user: User) => {
    return axios.post("/users/add", user);
}

export const update = (user: User) => {
    return axios.post("/users/update", user);
}

export const deleteById = (id: string) => {
    return axios.post("/users/delete",  { params: { id } });
}