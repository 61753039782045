import * as React from "react";
import { Switch, Route } from "react-router-dom";
import * as loadable from 'react-loadable';
import Loading from "./components/loading/Loading";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import { useThunkDispatch } from "./util/thunk-dispatch";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/App-State";
import { User } from "./interfaces/User";
import { Request } from "./interfaces/Request";
import { getCurrentUser } from "./features/user-feature";

const AsyncLoginContainer = loadable({ loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"), loading: Loading });
const AsyncLogoutContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"), loading: Loading });
const AsyncUserAddContainer = loadable({ loader: () => import(/* webpackChunkName: "UserAdd" */ "./containers/user/UserAdd"), loading: Loading });
const AsyncUserEditContainer = loadable({ loader: () => import(/* webpackChunkName: "UserEdit" */ "./containers/user/UserEdit"), loading: Loading });
const AsyncTicketListContainer = loadable({ loader: () => import(/* webpackChunkName: "TicketList" */ "./containers/ticket/TicketList"), loading: Loading });
const AsyncTicketAddContainer = loadable({ loader: () => import(/* webpackChunkName: "TicketAdd" */ "./containers/ticket/TicketAdd"), loading: Loading });
const AsyncTicketEvaluationContainer = loadable({ loader: () => import(/* webpackChunkName: "TicketEvaluation" */ "./containers/ticket/TicketEvaluation"), loading: Loading });
const AsyncTicketEditContainer = loadable({ loader: () => import(/* webpackChunkName: "TicketEdit" */ "./containers/ticket/TicketEdit"), loading: Loading });
const AsyncCustomerListContainer = loadable({ loader: () => import(/* webpackChunkName: "CustomerList" */ "./containers/customer/CustomerList"), loading: Loading });
const AsyncCustomerDetailsContainer = loadable({ loader: () => import(/* webpackChunkName: "CustomerDetails" */ "./containers/customer/CustomerDetails"), loading: Loading });
const AsyncCustomerAddContainer = loadable({ loader: () => import(/* webpackChunkName: "CustomerAdd" */ "./containers/customer/CustomerAdd"), loading: Loading });
const AsyncCustomerAddHoursContainer = loadable({ loader: () => import(/* webpackChunkName: "CustomerAddHours" */ "./containers/customer/CustomerAddHours"), loading: Loading });
const AsyncCustomerEditHoursContainer = loadable({ loader: () => import(/* webpackChunkName: "CustomerEditHours" */ "./containers/customer/CustomerEditHours"), loading: Loading });
const AsyncCustomerUserListContainer = loadable({ loader: () => import(/* webpackChunkName: "UserList" */ "./containers/user/UserList"), loading: Loading });

const Routes: React.FC = () => {
    const asyncDispatch = useThunkDispatch();
    const actions = bindActionCreators({ getCurrentUser }, asyncDispatch);
    const requests = useSelector<AppState, Request[]>(state => state.requests);
    const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");
    const user = useSelector<AppState, User>((state) => state.user);

    React.useEffect(() => {
        actions.getCurrentUser();
    }, []);
    
    React.useEffect(() => {
        var userRequestString = requests.filter(x => x.type === "user/GET_CURRENT").length > 0 ? requests.filter(x => x.type === "user/GET_CURRENT")[0].status : "REQUEST";
        setUserRequestStatus(userRequestString);
    }, [requests]);
    
    return (
        <Switch>
            <Route exact path="/login/:url?/:key?" render={() => <AsyncLoginContainer />} />
            <Route exact path="/logout" render={() => <AsyncLogoutContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/" render={() => <AsyncTicketListContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/ticket-edit/:id" render={() => <AsyncTicketEditContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/ticket-add" render={() => <AsyncTicketAddContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/ticket-evaluation" render={() => <AsyncTicketEvaluationContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/user-list" render={() => <AsyncCustomerUserListContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/user-add" render={() => <AsyncUserAddContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/user-edit/:id" render={() => <AsyncUserEditContainer />}/>
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/customer-list" render={() => <AsyncCustomerListContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/customer-details/:id" render={() => <AsyncCustomerDetailsContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/customer-add" render={() => <AsyncCustomerAddContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/customerHours-add/:id" render={() => <AsyncCustomerAddHoursContainer />} />
            <ProtectedRoute  isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" } exact path="/customerHours-edit/:id" render={() => <AsyncCustomerEditHoursContainer />} />
        </Switch>
    );
}

export default Routes;